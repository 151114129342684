<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="点击搜索证书"
            @click="search"
            readonly
            ref="keyword"
          ></van-search>
        </van-col>
      </van-row>
    </div>
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item>
        <img
          class="img"
          :src="require('../../../../assets/images/cert/b1.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="classify">
      <van-grid>
        <van-grid-item
          :text="classify.name"
          v-for="classify in classifys"
          :key="classify.code"
          @click="classifySearch(classify.code)"
        >
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="classify.icon"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="更多" @click="classifySearch('')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-more"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 热门证书</van-col
        >
        <van-col span="12" class="btn color-m"
          ><div @click="search">全部证书 <van-icon name="arrow" /></div
        ></van-col>
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveDisplayCertPage"
      >
        <template #default>
          <div
            class="major"
            v-for="cert in certList"
            :key="cert.code"
            @click="detail(cert.code, cert.issuerCode)"
          >
            <div class="name text-short">
              {{ cert.name }}
            </div>
            <div class="grade">
              颁发机构：<van-tag :color="COLOR_M">{{
                cert.issuerName
              }}</van-tag>
            </div>
          </div>
        </template>
      </van-list>
    </div>
    <Clue ref="clue" />
    <Call
      ref="call"
      win-show="false"
      entry-show="true"
      clue-model="CERT_CONSULT_ONLINE"
    />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="CRT" module-label="CRT_HOME_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
